import React, { useEffect, useLayoutEffect, useState, useRef, lazy, Suspense} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Spin } from 'antd'
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from 'react-responsive-carousel'

import Header from '../Header/Header'
import { content } from '../../constants/langs'
import { getChess, getNews } from '../../core/actions/restChessActions'
import { isMobile } from '../../core/actions/changeTextAction'
import { selectedModule } from '../../core/actions/changeTextAction'
import style from './Main.module.scss'

const Footer = lazy(() => import('../Footer/Footer'))

export default function Main() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const hiddenRef = useRef()

  const news = useSelector(({restChessReducer: { news }}) => news)
  const lang = useSelector(({changeTextReducer: { lang }}) => lang)
  const text = useSelector(({changeTextReducer: { text }}) => text)

  const [mobile, setMobile] = useState(false)
  const [showFooter, setShowFooter] = useState(false)
  const [loadImg, setLoadImg] = useState(false)
  const [showTitle, setShowTitle] = useState(true)
  const [ showPayment, setShowPayment ] = useState(false)
  const [ showInstallment, setShowInstallment ] = useState(false)
  const [ showCredit, setShowCredit ] = useState(false)

  useLayoutEffect(() => {
    const userAgent = navigator.userAgent
    const mobile = /mobile|iphone|ipad|ipod|android|blackberry|mini|windows\sce|palm/i.test(userAgent)
    setMobile(mobile)
    dispatch(isMobile(mobile))
  }, [])

  useEffect(() => {
    setTimeout(() => setShowTitle(false), 2500)
  }, [])

  useEffect(() => {
    dispatch(getChess())
  }, [])
  useEffect(() => {
    dispatch(getNews())
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler)
    return () => window.removeEventListener('scroll', scrollHandler)
  }, [])
  const scrollHandler = () => {
    if(window.pageYOffset + window.innerHeight >= hiddenRef.current.offsetTop) setShowFooter(true)
  }
  const toChess = () => {
    dispatch(selectedModule("Шахматка"))
    navigate("/chess")
}
  return (
    <div className={style.mainWrapper}>
      <Header/>
      <div className={style.wpapMainTitle} style={{display: !showTitle || loadImg ? "none" : ""}}>
        <div className={style.centered }>
          <span>СТАРТ &nbsp; ПРОДАЖ &nbsp; <br/> ЖИЛОГО &nbsp; ДОМА &nbsp; №1</span>
        </div>
      </div>
      <Carousel
        autoPlay={true}
        infiniteLoop={true}
        preventMovementUntilSwipeScrollTolerance={true}
        stopOnHover={true}
        interval={3500}
        dynamicHeight={true}
        showThumbs={false}
        //style={{display: loadImg ? "" : "none"}}
      >
        <div className={style.carouselImgs}>
          <img src={require('./images/home1.webp')} alt='home1'
          onLoad={() => setLoadImg(true)}
          />
        </div>
        <div className={style.carouselImgs}>
          <img src={require('./images/home2.webp')} loading="lazy" alt='home2'/>
        </div>
        <div className={style.carouselImgs}>
          <img src={require('./images/home3.webp')} loading="lazy" alt='home3'/>
        </div>
        <div className={style.carouselImgs}>
          <img src={require('./images/home4.webp')} loading="lazy" alt='home4'/>
        </div>
        <div className={style.carouselImgs}>
          <img src={require('./images/home5.webp')} loading="lazy" alt='home5'/>
        </div>
      </Carousel>
      <a name="О_комплексе"></a>
      <div className={style.wrapAdvantages}>
        <span className={style.title} style={{fontSize: !mobile ? 44 : mobile && text ? 28 : 22}}>{content.aboutComplex[lang].title}</span>
        <div className={style.advantages}>
          <div className={style.block}>
            <div className={style.flat1Image}/>
            <span className={style.titleAdvant} style={{fontSize: text ? 28 : 22}}>{content.aboutComplex[lang].titleAdvant1}</span>
            <span className={style.textAdvant} style={{fontSize: text ? 23 : 16}} >{content.aboutComplex[lang].text1}</span>
          </div>
          <div className={style.blockMain}>
            <div className={style.homeImage}/>
            <span className={style.titleAdvant} style={{fontSize: text ? 28 : 22}}>{content.aboutComplex[lang].titleAdvant2}</span>
            <span className={style.textAdvant} style={{fontSize: text ? 23 : 16}}>{content.aboutComplex[lang].text2}</span>
          </div>
          <div className={style.block}>
            <div className={style.lotusImage}/>
            <span className={style.titleAdvant} style={{fontSize: text ? 28 : 22}}>{content.aboutComplex[lang].titleAdvant3}</span>
            <span className={style.textAdvant} style={{fontSize: text ? 23 : 16}}>{content.aboutComplex[lang].text3}</span>
          </div>
        </div>
        <span className={style.title} style={{marginTop: 60, fontSize: !mobile ? 44 : mobile && text ? 28 : 22}}>{content.scheme[lang].title1}</span>
        <img src={require('./images/home8.webp')} rel="preconnect" alt='home8'/>
      </div>
      <div className={style.wrapPeculiarities} ref = {hiddenRef}>
        <span className={style.title} style={{fontSize: !mobile ? 44 : mobile && text ? 28 : 22}}>{content.peculiarities[lang].title}</span>
        <span className={style.text} style={{marginBottom: 30, fontSize: text ? 24 : mobile && !text ? 17 : 20}}>{content.peculiarities[lang].text}</span>
      </div>
      <div className={style.wrapMap}>
        <span className={style.title} style={{fontSize: !mobile ? 44 : mobile && text ? 28 : 22}}>{content.location[lang].title}</span>
        <span className={style.text} style={{marginBottom: 30, fontSize: text ? 24 : mobile && !text ? 17 : 20}}>{content.location[lang].text}</span>
        <div className={style.map}>
          <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Ab97b3af08ced37d8a4a9baff14c6b3da02b4407f0a172ad7c953433720391ac0&amp;" title="" width="100%" height="100%" frameborder="0"></iframe>
        </div>
      </div>
      <div className={style.wrapScheme}>
        <div className={style.wrapBlock}>
          <span className={style.title} style={{fontSize: !mobile ? 44 : mobile && text ? 28 : 22}}>{content.scheme[lang].title2}</span>
          <img src={require('./images/numsFloors.webp')} rel="preconnect"  height="auto" width="100%" alt='home3'/>
        </div>
        <div className={style.wrapBlock} style={{marginTop: 60}}>
          <span className={style.title} style={{fontSize: !mobile ? 44 : mobile && text ? 28 : 22}}>{content.infrastructure[lang].title}</span>
          <span className={style.text} style={{fontSize: text ? 24 : mobile && !text ? 17 : 20}}>{content.infrastructure[lang].text}</span>
          {/* <span className={style.title} style={{fontSize: !mobile ? 44 : mobile && text ? 28 : 22}}>{content.scheme[lang].title2}</span> */}
          <img src={require('./images/master-plan.webp')} rel="preconnect" height="auto" width="100%" alt='home3' style={{marginTop: 40}}/>
        </div>
      </div>
      <a name="Условия_оплаты"></a>
      <div className={style.wrapPaymenTerms}>
        <span className={style.title} style={{fontSize: !mobile ? 44 : mobile && text ? 28 : 22}}>{content.paymentTerms[lang].title}</span>
        <div className={style.wrapModule}>
          <div className={showPayment ? style.showModule : style.module}
            onClick={() => setShowPayment(item => !item)}
          > <span className={style.titleModule} style={{fontSize: text ? 30 : 24}}>{content.paymentTerms[lang].titleModule1}</span>
            <span className={style.textModule} style={{display: showPayment ? "flex" : "none", fontSize: text ? 24 : 18}}>
              {content.paymentTerms[lang].textModule1}
            </span>
            <img style={{display: showPayment ? "none" : ""}} className={style.arrow} src={require('./images/arrow.webp')} alt='arrow'/>
          </div>
          <div className={showInstallment ? style.showModule : style.module}
            onClick={() => setShowInstallment(item => !item)}
          >
            <span className={style.titleModule} style={{fontSize: text ? 30 : 24}}>{content.paymentTerms[lang].titleModule2}</span>
            <span className={style.textModule} style={{display: showInstallment ? "flex" : "none", fontSize: text ? 24 : 18}}>
              {content.paymentTerms[lang].textModule2}
            </span>
            <img style={{display: showInstallment ? "none" : ""}} className={style.arrow} src={require('./images/arrow.png')} alt='arrow'/>
          </div>
          <div className={showCredit ? style.showModule : style.module}
            onClick={() => setShowCredit(item => !item)}
          >
            <span className={style.titleModule} style={{fontSize: text ? 30 : 24}}>{content.paymentTerms[lang].titleModule3}</span>
            <span className={style.textModule} style={{display: showCredit ? "flex" : "none", fontSize: text ? 24 : 18}}>
             {content.paymentTerms[lang].textModule3}
            </span>
            <img style={{display: showCredit ? "none" : ""}} className={style.arrow} src={require('./images/arrow.png')} alt='arrow'/>
          </div>
        </div>
      </div>
      <div className={style.wrapNews}>
        <span className={style.title} style={{display: news ? "" : "none"}}>{lang === "ru" ? "НОВОСТИ" : lang === "en" ? "NEWS": lang === "br" ? "НАВIНЫ" : ""}</span>
        <span className={style.text} style={{fontSize: text ? 24 : mobile && !text ? 17 : 20}}>{lang === "ru" ? news?.[0]
        .newsRu.split("").map(item => {
          if(item === "\n")return <br/>
          else return item
        }) 
        : lang === "br" ? news?.[1]
        .newsBr.split("").map(item => {
          if(item === "\n")return <br/>
          else return item
        }) 
        : lang === "en" ? news?.[2]
        .newsEn.split("").map(item => {
          if(item === "\n")return <br/>
          else return item
        }) 
        : ""}</span>
      </div>
      <div className={style.wrapChooseFlat}
          onClick={toChess}
      >
          <div className={style.flatImage}/>
          {content.header[lang].chooseFlat}
      </div>
      <a name="Контакты"></a>
      <span className={style.title} style={{fontSize: !mobile ? 44 : mobile && text ? 28 : 22, marginTop: 80}}>{content.footer.contact[lang].title}</span>
      {
        showFooter && (
          <Suspense fallback={<Spin size="large"/>}>
            <Footer/>
          </Suspense>
        )
      }
      <div className={style.wrapCreatedPF}>
        <img src={require('./images/site.webp')} style={{marginRight: 10}} width='32' height='28' alt='mobile app'/>
        <span>Created by&nbsp;&nbsp;</span>
        <a style={{color: 'black'}} href="https://create-site.by/">https://create-site.by</a>
      </div>
    </div>
  )
}
