import { takeEvery, put, call, select } from 'redux-saga/effects'
import { SEND_DATA, sendDataSuccess } from '../actions/restChessActions'
import httpProvider from '../../common/httpProvider'
import { SEND_URL } from '../../common/api'

function* workerLoader() {
    const info = yield select(state => state.restChessReducer.data)
    try {
      const { data } = yield call(httpProvider.post, SEND_URL, {data: 
        {
          fields:
            {
              TITLE: info?.flat ? `"ЖК Андреевский". Квартира № ${info?.flat}` : "ЖК Андреевский",
              NAME: info?.name,
              EMAIL: [
                { 
                  VALUE: info?.email,
                  VALUE_TYPE: "WORK",
                }
              ],
              STATUS_ID: "NEW",
              OPENED: "Y",
              ASSIGNED_BY_ID: 42,
              CURRENCY_ID: "BYN",
              OPPORTUNITY: info?.cost,
              PHONE: [
                { 
                  VALUE: info?.phone,
                  VALUE_TYPE: "WORK",
                },
              ],
              COMMENTS: `${info?.comment ? info?.comment : ""}`  + "\n" +  `${info?.checked ? "В кредит/рассрочку" : ""}`
            },
              params: {
                REGISTER_SONET_EVENT: "Y",
            }
          }
        })
        yield put(sendDataSuccess(data))
      } catch (error) {
        yield put(console.log(error))
      }
  }

export default function* watcherSendData() {
  yield takeEvery(SEND_DATA, workerLoader)
}