import React, { useLayoutEffect, lazy, Suspense } from 'react'
import { Route,  Routes, useLocation } from 'react-router-dom'

import Main from '../Main/Main'
import Chess from '../Chess/Chess'

import style from './App.module.scss'

export default function App() {
  const location = useLocation()
  
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return (
    <div className={style.app}>
      <Routes>
        <Route path="/" element={<Main/>}/>
        <Route path="/chess" element={<Chess/>}/>
      </Routes>
    </div>
  )
}